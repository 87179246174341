"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_POS_WITH_OFFERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_POS_WITH_OFFERS = client_1.gql `
  query getPoswithOffersAndMenus(
    $offerTemplateType: String!
    $offerTemplateWithdrawalType: String!
    $filters: [FilterType]
  ) {
    getPoswithOffersAndMenus(
      querySearch: [
        { key: "type", operator: "*", value: $offerTemplateType }
        { key: "withdrawalType", operator: "*", value: $offerTemplateWithdrawalType }
      ],
      filters: $filters
  ) {
      filteredPos {
          id
          idPos: numericId
          concept {
            image
            name
          }
          type
          atSiteTakeAwayType
          name
          status
          schedules {
            days
            hours
          }
          zone {
            id
            name
            holding {
              id
              name
              hasAffluence
            }
          }
          image
          content
          typeModel{
            name
            group
            image
          }
          activeOfferTemplates {
            salesType
            withdrawalType
          }
          bookingOfferTemplate {
            id
            idPos
          }
        }
      defaultFilters{
        offerTypes
        posTypes
        zones {
          id
          name
        }
      }
    }
  }
`;
