import { RestaurantCard } from "@atomic";
import { INode, IPos } from "@foodi/core";
import { NavigationProp } from "@react-navigation/native";
import { getDecodedId, TestIDs } from "@utils";
import React from "react";
import { FlatList } from "react-native";
import { useDevices } from "@hooks";
import { ItemContainer } from "./RestaurantCardsList.style";
import { PosAffluence } from "@foodi/core/lib/domain/entities/PosAffluence";
import { RestaurantCardViewModel } from "@modules";

interface IProps {
  restaurantCardsList?: IPos[];
  navigation?: NavigationProp<any>;
  currentTab?: number;
  affluence: PosAffluence[];
}

const RESTAURANT_CARD_LIST_COLUMNS = 3;

export const RestaurantCardsList: React.FC<IProps> = React.memo(
  ({ restaurantCardsList, navigation, currentTab, affluence }) => {

    const restaurantCardViewModel = new RestaurantCardViewModel();

    const [isMobile] = useDevices();

    const renderItem = ({ item, index }: any) => {
      const hasGap = (index + 1) % RESTAURANT_CARD_LIST_COLUMNS !== 0;
      const posAffluence = affluence.find((affluence: PosAffluence) => affluence.idPos == getDecodedId(item?.id)?.split(":")?.[1]);
      return (
        <ItemContainer key={`${index}_${item?.name}_${item?.id}`} hasGap={hasGap} isMobile={isMobile}>
          <RestaurantCard
            restaurantCard={item}
            navigation={navigation}
            currentTab={currentTab}
            affluence={posAffluence ? {...posAffluence, ...restaurantCardViewModel.getAffluence(posAffluence) } : null}
          />
        </ItemContainer>
      );
    };

    return (
      <FlatList
        testID={TestIDs.home.restaurantCard.views.restaurantCardListContainer}
        data={restaurantCardsList}
        key={isMobile ? `mobile_${JSON.stringify(restaurantCardsList)}` : `desktop_${JSON.stringify(restaurantCardsList)}`}
        renderItem={renderItem}
        keyExtractor={(item: IPos, index: number) => `${index}_${item?.id}_${item?.name}`}
        numColumns={!isMobile ? RESTAURANT_CARD_LIST_COLUMNS : 1}
      />
    );
  }
);
