import { Dispatch, ReactNode } from "react";
import { PixelRatio } from "react-native";
import { I18n } from "react-redux-i18n";
import moment, { Moment } from "moment";
import { isEmpty } from "lodash";

import { LowAffluence } from "@assets/icons/Affluence/LowAffluence";
import { UnknownAffluence } from "@assets/icons/Affluence/UnknownAffluence";
import { FullAffluence } from "@assets/icons/Affluence/FullAffluence";
import { HighAffluence } from "@assets/icons/Affluence/HighAffluence";

import {
  IPos,
  IPosSchedule,
  IPosStatus,
  OfferTemplateWithdrawalType,
  OfferTemplateType
} from "@foodi/core";
import { PointOfSaleThunks } from "@modules";
import { getCloudImageUrl } from "@utils";
import { EatIn, SeeAll, TakeAway, Images } from "@assets";
import { PosAffluence } from "@foodi/core/lib/domain/entities/PosAffluence";

interface IPosStatusSchedule {
  status: IPosStatus;
  schedules?: string[];
  nextHour?: string;
}

interface IRestaurantCardType {
  title: string;
  isSelected: boolean;
  type: TabsType;
  icon: ReactNode;
}

export interface IMappedSchedules {
  day: number;
  schedule: string[];
}

interface IndexedNextDaysMap {
  day: number;
  index: number;
}

export interface IFormattedSchedules {
  dayWeekName?: string;
  schedules?: string;
  isCurrentDay?: boolean;
}

export enum PosType {
  SELF = "SELF",
  CAFETERIA = "CAFETERIA",
  BRASSERIE = "BRASSERIE",
  OTHER = "OTHER",
}

export enum PosStatus {
  OPENED = "OPENED",
  CLOSED = "CLOSED",
  UNKNOWN = "UNKNOWN",
}

export enum AtSiteTakeAwayType {
  AT_SITE = "AT_SITE",
  TAKE_AWAY = "TAKE_AWAY",
  AT_SITE_TAKE_AWAY = "AT_SITE_TAKE_AWAY",
  NONE = "NONE",
}

export enum TabsType {
  SEE_ALL = "SEE_ALL",
  EAT_IN = "EAT_IN",
  TAKE_OUT = "TAKE_OUT",
}

export enum AffluenceState {
  UNKNOWN = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export class RestaurantCardViewModel {
  private status: IPosStatus = PosStatus.CLOSED;
  private schedules: string[] = [];
  private nextHour: string = "";
  private tabs: IRestaurantCardType[] = [
    {
      title: I18n.t("refill.seeAll"),
      isSelected: true,
      type: TabsType.SEE_ALL,
      icon: SeeAll,
    },
    {
      title: I18n.t("homepage.restaurantCard.eatIn"),
      isSelected: false,
      type: TabsType.EAT_IN,
      icon: EatIn,
    },
    {
      title: I18n.t("homepage.restaurantCard.takeOut"),
      isSelected: false,
      type: TabsType.TAKE_OUT,
      icon: TakeAway,
    },
  ];

  getInitTabs = (): IRestaurantCardType[] => {
    return this.tabs;
  };

  getTabs = (tabIndex: number): IRestaurantCardType[] => {
    return this.tabs.map((tab, index) => ({
      ...tab,
      isSelected: tabIndex === index,
    }));
  };

  getCards = (
    atSiteTakeAwayType: string[],
    withdrawalType: string[],
    restaurantCardsList?: IPos[]
  ) =>
    restaurantCardsList?.filter(
      (node) =>
        atSiteTakeAwayType.includes(node.atSiteTakeAwayType) ||
        node.activeOfferTemplates?.some((offer) =>
          withdrawalType.includes(offer.withdrawalType)
        )
    );

  getCardsFiltered = (restaurantCardsList?: IPos[]) => {
    return restaurantCardsList
      ?.filter?.(
        (r: IPos) =>
          r?.atSiteTakeAwayType !== AtSiteTakeAwayType.NONE
      );
  };

  getRestaurantCards = (
    tabIndex: number,
    restaurantCardsList?: IPos[]
  ) => {
    const tab = this.tabs[tabIndex].type;
    switch (tab) {
      case TabsType.SEE_ALL:
        return this.getCardsFiltered(restaurantCardsList);
      case TabsType.EAT_IN:
        return this.getCardsFiltered(
          this.getCards(
            [AtSiteTakeAwayType.AT_SITE, AtSiteTakeAwayType.AT_SITE_TAKE_AWAY],
            [
              OfferTemplateWithdrawalType.POS_AT_SITE,
              OfferTemplateWithdrawalType.POS_CLICK_SERVE,
              OfferTemplateWithdrawalType.TABLE_SERVICE,
            ],
            restaurantCardsList
          )
        );
      case TabsType.TAKE_OUT:
        return this.getCardsFiltered(
          this.getCards(
            [
              AtSiteTakeAwayType.TAKE_AWAY,
              AtSiteTakeAwayType.AT_SITE_TAKE_AWAY,
            ],
            [
              OfferTemplateWithdrawalType.CONNECTED_LOCKERS,
              OfferTemplateWithdrawalType.POS_TAKE_AWAY,
            ],
            restaurantCardsList
          )
        );
    }
  };

  getInfoMessage = (tabIndex: number) => {
    const tab = this.tabs[tabIndex]?.type;
    return I18n.t(
      `homepage.restaurantCard.${
        tab === TabsType.EAT_IN
          ? "orderNotAvailableEatIn"
          : "orderNotAvailableTakeAway"
      }`
    );
  };

  getStatus = (): boolean => this.status === PosStatus.OPENED;

  getUri = (restaurantCard: IPos) =>
    !isEmpty(restaurantCard?.image)
      ? getCloudImageUrl(
          restaurantCard?.image,
          "height",
          String(PixelRatio.getPixelSizeForLayoutSize(648))
        )
      : Images.defaultPosImg;

  getService = (restaurantCard: IPos) =>
    I18n.t(
      restaurantCard?.bookingOfferTemplate?.length
        ? "homepage.restaurantCard.booking"
        : restaurantCard?.activeOfferTemplates?.length
        ? "homepage.restaurantCard.clickAndCollect"
        : ""
    );

  isBookingService = (restaurantCard: IPos) =>
    restaurantCard?.bookingOfferTemplate?.length;

  getOpenHours = (formattedHours: string[]) => {
    return !this.getStatus()
      ? this.getOpenedHours(formattedHours)
      : this.schedules;
  };

  getHoursToShow = (formattedHours: string[], openHours: string[]) =>
    formattedHours?.length > 1 ? `${openHours?.[0]}-${openHours?.[1]}` : null;

  getShowMore = (formattedHours: string[]): string =>
    formattedHours?.length > 2 ? " ..." : "";

  formatScheduleHours = (hours: string[]): string[] =>
    hours.map((hour: string) => moment.utc(hour, "HH:mm:ss").format("H:mm"));

  getScheduleHoursOfDay = (
    schedules: IPosSchedule[],
    dayOfWeek: number = moment().isoWeekday()
  ): string[] => {
    for (const schedule of schedules) {
      if (schedule.days.includes(dayOfWeek)) {
        return schedule.hours;
      }
    }

    return [];
  };

  getOpenedHours = (formattedHours: string[]): string[] => {
    for (let i = 0; i < formattedHours.length; i = i + 2) {
      const start: Moment = moment(formattedHours[i], "HH:mm:ss");

      if (moment().isBefore(start)) {
        return [formattedHours[i], formattedHours[i + 1]];
      }
    }
    return [formattedHours[0], formattedHours[1]];
  };

  getPosStatusFromSchedules = (
    schedules: IPosSchedule[]
  ): IPosStatusSchedule => {
    if (schedules.length === 0) {
      this.status = PosStatus.UNKNOWN;
      return { status: this.status };
    }

    const scheduleHours = this.getScheduleHoursOfDay(schedules);
    const now: Moment = moment();

    for (let i = 0; i < scheduleHours.length; i = i + 2) {
      const start: Moment = moment(scheduleHours[i], "HH:mm:ss");
      const end: Moment = moment(scheduleHours[i + 1], "HH:mm:ss");
      const displaystart: Moment = moment.utc(scheduleHours[i], "HH:mm");
      const displayend: Moment = moment.utc(scheduleHours[i + 1], "HH:mm");

      if (now.isBetween(start, end, undefined, "[]")) {
        this.status = PosStatus.OPENED;
        this.schedules = [displaystart.format("H:mm"), displayend.format("H:mm")];
        return {
          status: this.status,
          schedules: this.schedules,
        };
      }
    }
    this.status = PosStatus.CLOSED;
    return { status: this.status };
  };

  formatScheduleText = (): string => {
    let scheduleText = "";

    if (this.status === PosStatus.OPENED) {
      scheduleText = `${I18n.t('restaurantDetail.closesAt')} ${this.nextHour}`;
    } else if (this.status === PosStatus.CLOSED){
      scheduleText = `${I18n.t('restaurantDetail.opensAt')} ${this.nextHour}`;
    }

    return scheduleText;
  };

  formattedScheduleList = (schedulesList: IMappedSchedules[], language?: string): IFormattedSchedules[] => {
    let formattedScheduleList: IFormattedSchedules[] = [];

    for (let weekDay = 1; weekDay <8; weekDay++) {
      const indexOfCurrentDay = schedulesList.findIndex(schedule => schedule.day === weekDay);
      const isCurrentDay = weekDay === moment().isoWeekday();
      const dayWeekName =  moment()
                          .isoWeekday(weekDay)
                          .locale(language || 'fr')
                          .format('dddd');

      if (indexOfCurrentDay === -1) {
        formattedScheduleList.push({
          dayWeekName,
          schedules: I18n.t('restaurantDetail.closed'),
          isCurrentDay
        });
      } else {
        const scheduleHours = schedulesList[indexOfCurrentDay]?.schedule;
        const formattedHours = this.formatScheduleHours(scheduleHours);
        let scheduleText = `${formattedHours[0]} - ${formattedHours[1]}`;

        for (let i = 2; i < formattedHours.length; i += 2) {
          scheduleText += `, ${formattedHours[i]} - ${formattedHours[i + 1]}`;
        }

        formattedScheduleList.push({
          dayWeekName,
          schedules: scheduleText,
          isCurrentDay
        });
      };
   }
    return formattedScheduleList;
  };

  getPosStatusFromMappedSchedules = (
    mappedSchedules: IMappedSchedules[]
  ): IPosStatusSchedule => {
    const now: Moment = moment();
    const dayOfWeek = now.isoWeekday();

    if (mappedSchedules.length === 0) {
      this.status = PosStatus.UNKNOWN;
      this.schedules = [""];
      this.nextHour = "";

      return { status: this.status,
        schedules: this.schedules,
        nextHour: this.nextHour
      };
    }

    const indexOfCurrentDay = mappedSchedules.findIndex(schedule => schedule.day === dayOfWeek);
    const scheduleHours = mappedSchedules[indexOfCurrentDay]?.schedule;

    if (scheduleHours !== undefined) {
      for (let i = 0; i < scheduleHours.length; i = i + 2) {
        const start: Moment = moment(scheduleHours[i], "HH:mm:ss");
        const displayStart: Moment = moment.utc(scheduleHours[i], "HH:mm:ss");
        const end: Moment = moment(scheduleHours[i + 1], "HH:mm:ss");
        const displayEnd: Moment = moment.utc(scheduleHours[i + 1], "HH:mm:ss");
        const nextStart: Moment = moment(scheduleHours[i + 2], "HH:mm:ss");
        const displayNextStart: Moment = moment.utc(scheduleHours[i + 2], "HH:mm:ss");
        const nextEnd: Moment = moment(scheduleHours[i + 3], "HH:mm:ss");

        if (now.isBefore(start)){
          this.status = PosStatus.CLOSED;
          this.schedules = [start.format("H:mm"), end.format("H:mm")];
          this.nextHour = displayStart.format("H[h]mm");

          return {
            status: this.status,
            schedules: this.schedules,
            nextHour: this.nextHour
          };

        }

        if (now.isBetween(start, end, undefined, "[]")) {
          this.status = PosStatus.OPENED;
          this.schedules = [start.format("H:mm"), end.format("H:mm")];
          this.nextHour = displayEnd.format("H[h]mm");
          return {
            status: this.status,
            schedules: this.schedules,
            nextHour: this.nextHour,
          };
        } else if (now.isBetween(end, nextStart, undefined, "[]")){
          this.status = PosStatus.CLOSED;
          this.schedules = [nextStart.format("H:mm"), nextEnd.format("H:mm")];
          this.nextHour = displayNextStart.format("H[h]mm");
          return {
            status: this.status,
            schedules: this.schedules,
            nextHour: this.nextHour,
          };
        }
      }
    }

    const nextDaysMap = this.getIndexedNextDaysMap(mappedSchedules);
    let nextScheduleHours: string[] = []
    if (nextDaysMap.length > 1) {
      const nextDayIndex = nextDaysMap[1].index
      nextScheduleHours = mappedSchedules[nextDayIndex].schedule;
    } else {
      const nextDayIndex = nextDaysMap[0].index
      nextScheduleHours = mappedSchedules[nextDayIndex].schedule;
    }

    const nextStart: Moment = moment(nextScheduleHours[0], "HH:mm:ss");
    const nextEnd: Moment = moment(nextScheduleHours[1], "HH:mm:ss");
    const nextSchedules = [nextStart.format("H:mm"), nextEnd.format("H:mm")];

    this.status = PosStatus.CLOSED;
    this.schedules = nextSchedules;
    this.nextHour = nextStart.format("H[h]mm");

    return {
      status: this.status,
      schedules: this.schedules,
      nextHour: this.nextHour,
    };
  };

  getMappedSchedules = (schedules: IPosSchedule[]):  IMappedSchedules[] => {
    let mappedSchedules = [];
    for (const schedule of schedules) {
      for (const day of schedule.days) {
        mappedSchedules.push({
          day,
          schedule: schedule.hours,
        });
      }
    }
    return mappedSchedules;
  };

  getIndexedNextDaysMap = (schedules: IMappedSchedules[]):  IndexedNextDaysMap[] => {
    let indexedNextDaysMap: IndexedNextDaysMap[]  = [];
    let currentWeekDay = moment().isoWeekday();
    for (let weekDay = 1; weekDay < 8; weekDay++) {

      const indexOfNextDay = schedules.findIndex(schedule => schedule.day === currentWeekDay);
      if (indexOfNextDay !== -1) {
        indexedNextDaysMap.push({
          day: currentWeekDay,
          index: indexOfNextDay,
        })
      }

      currentWeekDay === 7 ? currentWeekDay = 1 : currentWeekDay++;
    }
    return indexedNextDaysMap;
  };

  getRestaurantDescription = (name: string, description?: string): string => {
    return !isEmpty(description) && description !== undefined
      ? description
      : I18n.t("restaurantDetail.restaurantDescription", { name });
  };

  getAffluenceEnabled = () => {
    return true;
  }

  getAffluence = ({ state }: PosAffluence) => {
    let icon = UnknownAffluence;
    let label = 'homepage.restaurantCard.affluence.unknown';

    if (state === AffluenceState.LOW) {
      icon = LowAffluence;
      label = 'homepage.restaurantCard.affluence.low';
    }
    else if (state === AffluenceState.MEDIUM) {
      icon = HighAffluence;
      label = 'homepage.restaurantCard.affluence.high';
    }
    else if (state === AffluenceState.HIGH) {
      icon = FullAffluence;
      label = 'homepage.restaurantCard.affluence.full';
    }

    return {
      icon,
      label
    }
  }

  getPosFiltered = async ({
    dispatch,
    filters
  }: {
    dispatch: Dispatch<any>,
    filters?: {
      type: string;
      value: string;
  }[];
  }) => {

    const pointOfSales = await dispatch(
			PointOfSaleThunks.getPosWithOffers({
				offerTemplateType: `${OfferTemplateType.CLICK_COLLECT},${OfferTemplateType.TABLE_SERVICE},${OfferTemplateType.BOOKING_SERVICE}`,
				offerTemplateWithdrawalType: `${OfferTemplateWithdrawalType.CONNECTED_LOCKERS},${OfferTemplateWithdrawalType.POS_AT_SITE},${OfferTemplateWithdrawalType.POS_TAKE_AWAY},${OfferTemplateWithdrawalType.TABLE_SERVICE},${OfferTemplateWithdrawalType.POS_CLICK_SERVE},${OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT},${OfferTemplateWithdrawalType.CLICK_AND_PICK_UP}`,
        filters
			})
    );

    return {
      posCards: this.getCardsFiltered((pointOfSales as any)?.data?.filteredPos) || [],
      defaultFilters: (pointOfSales as any)?.data?.defaultFilters
    };

  }
}
